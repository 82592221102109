<template>
  <b-container :style="this['events/handleMinHeight']" class="wall text-dark fontStandard" fluid>
    <h1 class="text-center text-white pt-5">
      <i class="big_text fas fa-chalkboard-teacher pb-1" />
      <br />

      <b-badge pill variant="light">
        <strong class="fontStandard text-primary">
          <BTextUppercase>Mon Portfolio</BTextUppercase>
        </strong>
      </b-badge>
    </h1>

    <!-- fastNav -->
    <div class="sticky-top top60px text-right" style="z-index:1;">
      <b-badge variant="warning" class="shadow">
        <b-button
          squared
          variant="warning"
          v-for="(v,i) in fastNav"
          :key="i"
          @click="$tools.handleSmooth( v.link )"
          :size="isMobileAspect ? 'md' : 'sm'"
        >{{ isMobileAspect ? i : v.text }}</b-button>
      </b-badge>
    </div>
    <!-- fastNav -->

    <b-row>
      <b-col cols="0" xl="2" />
      <b-col cols="12" xl="8">
        <!-- Les petits sabots 64 -->
        <b-container id="Smooth-1" class="paddingTopBottomLarge" fluid>
          <h1 class="text-light fontCustom addBeforeDot">Application Web: Les petits sabots 64</h1>

          <b-card no-body class="overflow-hidden border-dark text-center">
            <b-row no-gutters>
              <b-col cols="12" order="2">
                <b-carousel
                  id="carousel-no-animation"
                  :interval="5000"
                  no-hover-pause
                  class="rounded-0 scaleUpOnHover"
                >
                  <b-carousel-slide
                    v-for="( v, i ) in lespetitssabots64"
                    :key="i"
                    :caption="v.text"
                    img-alt="v.text"
                    :img-src="v.url"
                    caption-tag="h3"
                  />
                </b-carousel>
              </b-col>
              <b-col cols="12" class="fontStandard" order="1">
                <b-card-body title="Les petits sabots 64">
                  <b-card-text class="text-left">
                    <p data-aos="fade-left" data-aos-anchor-placement="bottom-bottom">
                      Dans le cadre du stage pour le titre professionnel, et en
                      raison de la crise sanitaire covid19, j'ai réalisé en
                      confinement une application web pour un centre équestre,
                      un site web vitrine affichant les diverses prestations.
                    </p>

                    <p data-aos="fade-right" data-aos-anchor-placement="bottom-bottom">
                      L'application web contiendra également via un espace
                      membres/administrateurs, toutes les informations des chevaux
                      et adhérents du centre équestre, comme les prochaines
                      vaccinations, avec un rappel automatisé soit par mail soit par
                      sms.
                    </p>

                    <p data-aos="fade-left" data-aos-anchor-placement="bottom-bottom">
                      L'application web a été réalisé avec Vue.js, Bootstrap,
                      PHP, MySQL.
                    </p>

                    <p data-aos="fade-right" data-aos-anchor-placement="bottom-bottom">
                      L'adresse de l'application web:
                      <b-link
                        href="https://www.lespetitssabots-64.ovh"
                        target="_blank"
                      >https://www.lespetitssabots-64.ovh</b-link>
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-container>
        <!-- Les petits sabots 64 -->
        <!-- Machine à sous -->
        <b-container id="Smooth-2" class="paddingTopBottomLarge" fluid>
          <h1
            class="text-light fontCustom addBeforeDot"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          >Composant: Machine à sous</h1>

          <b-card no-body class="overflow-hidden border-dark text-center">
            <b-row no-gutters>
              <b-col cols="12" order="2">
                <b-carousel
                  id="carousel-no-animation"
                  :interval="5000"
                  no-hover-pause
                  class="rounded-0 scaleUpOnHover"
                  style="max-width:100%;"
                >
                  <b-carousel-slide
                    v-for="( v, i ) in machine_slots"
                    :key="i"
                    :caption="v.text"
                    img-alt="v.text"
                    :img-src="v.url"
                    caption-tag="h3"
                  />
                </b-carousel>
              </b-col>
              <b-col cols="12" class="fontStandard" order="1">
                <b-card-body title="Composant Javascript/CSS3/PHP">
                  <b-card-text class="text-left">
                    <p data-aos="fade-right" data-aos-anchor-placement="bottom-bottom">
                      Une machine à sous provenant d'un précédent projet, entièrement remaniée et ré-écrite avec Vue dans un composant propre,
                      le script communique avec une API en PHP, pour échanger les données, au moment on clique sur Jouer, le script effectuera une requête au controlleur de
                      l'API et cette dernière connaîtra à l'avance le résultat, et retournera au script les valeurs des 3 cylindres, le script se chargera d'afficher le gain.
                    </p>
                    <p
                      data-aos="fade-right"
                      data-aos-anchor-placement="bottom-bottom"
                    >La machine à sous a été intégré au l'API en tant que controller/model, l'API est un framework que j'ai réalisé.</p>
                    <p data-aos="fade-right" data-aos-anchor-placement="bottom-bottom">
                      L'utilisation de la perspective a été utilisé dans la feuille de style CSS afin de réaliser un effet de cylindre qui tourne,
                      les 6 images sont automatiquement placé dans un effet de cylindre.
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
        </b-container>
        <!-- Machine à sous -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  components: { BTextUppercase: () => import("@/components/BTextUppercase") },
  computed: {
    ...mapState(["events"]),
    ...mapGetters(["events/handleMinHeight"]),
    isMobileAspect() {
      return this.events.width < 1200 ? true : false;
    },
    fastNav() {
      return [
        { link: "#Smooth-1", text: "Les petits sabots 64" },
        { link: "#Smooth-2", text: "Machine à sous" },
      ];
    },
    lespetitssabots64() {
      if (this.$tools.canUseWebP()) {
        return [
          {
            url: require("@/assets/webp/folio/lespetitssabots/1.webp"),
            text: "présentation vitrine",
          },
          {
            url: require("@/assets/webp/folio/lespetitssabots/2.webp"),
            text: "Mosaique d'images",
          },
          {
            url: require("@/assets/webp/folio/lespetitssabots/3.webp"),
            text: "Espaces Cavaliers/Ecurie",
          },
          {
            url: require("@/assets/webp/folio/lespetitssabots/4.webp"),
            text: "Cavalier qui consulte ses chevaux",
          },
          {
            url: require("@/assets/webp/folio/lespetitssabots/5.webp"),
            text: "Les prochains rappels vaccinations",
          },
          {
            url: require("@/assets/webp/folio/lespetitssabots/6.webp"),
            text: "On ajoute le cheval de Lucky Luke",
          },
        ];
      } else {
        return [
          {
            url: require("@/assets/img/folio/lespetitssabots/1.jpg"),
            text: "présentation vitrine",
          },
          {
            url: require("@/assets/img/folio/lespetitssabots/2.jpg"),
            text: "Mosaique d'images",
          },
          {
            url: require("@/assets/img/folio/lespetitssabots/3.jpg"),
            text: "Espaces Cavaliers/Ecurie",
          },
          {
            url: require("@/assets/img/folio/lespetitssabots/4.jpg"),
            text: "Cavalier qui consulte ses chevaux",
          },
          {
            url: require("@/assets/img/folio/lespetitssabots/5.jpg"),
            text: "Les prochains rappels vaccinations",
          },
          {
            url: require("@/assets/img/folio/lespetitssabots/6.jpg"),
            text: "On ajoute le cheval de Lucky Luke",
          },
        ];
      }
    },
    machine_slots() {
      if (this.$tools.canUseWebP()) {
        return [
          {
            url: require("@/assets/webp/folio/machineslots/1.webp"),
            text: "C'est gagné",
          },
          {
            url: require("@/assets/webp/folio/machineslots/2.webp"),
            text: "les 10 derniers gains en base de données",
          },
        ];
      } else {
        return [
          {
            url: require("@/assets/img/folio/machineslots/1.jpg"),
            text: "C'est gagné",
          },
          {
            url: require("@/assets/img/folio/machineslots/2.jpg"),
            text: "les 10 derniers gains en base de données",
          },
        ];
      }
    },
  },
};
</script>

<style scoped>
.top60px {
  top: 60px;
}
.addBeforeDot::before {
  content: "•";
}
</style>